import { classNames } from 'utils/helpers';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import { formatHours } from 'utils/helpers';
import { CloudIcon } from '@heroicons/react/24/outline';
import { CloudIcon as CloudIconSolid } from '@heroicons/react/24/solid';

import { useTranslation } from 'react-i18next';
import infinity_logo from 'assets/images/icon-infite.png';


export default function StorageIndicator({ 
  usedSpace, 
  totalSpace, 
  reverse,
  hasSubscription = true,
  showInMobile = false, 
  reverseMobile = false,
  upgrade = true,
  position = 'left',
  full = true,
  sm = false,
 }) {
  const { t } = useTranslation();
  const [percentageUsed, setPercentageUsed] = useState(0);
  const [active, setActive] = useState(false);

  const setColor = (active, percentageUsed) => {
    if (percentageUsed >= 95) return 'red'
    if (percentageUsed < 95) return 'violet'
  }

  useEffect(() => {
    const percentage = Math.round(100 * usedSpace / totalSpace)
    setPercentageUsed(percentage);
  }, [usedSpace, totalSpace]);

  return (
    <>
      <div className={`storage-indicator  justify-start ${full && 'w-full'}`}>
        {!hasSubscription && 
         <div className={classNames({
          'flex gap-2 !w-full justify-start md:justify-start !items-center ': true, 
          'flex-row-reverse md:flex-row': reverseMobile,
          'md:justify-end': position === 'right',
          })}>
          <div
            className={classNames({
              'hidden md:flex flex-col w-full md:w-auto justify-center gap-1.5': true,
              'items-end': !reverse && !reverseMobile,
              '!flex': showInMobile,
            })}>
              <div className={classNames({
                'flex flex-row gap-1 text-sm font-archivo ': true,
                'justify-end': reverse || reverseMobile
              })}>
                <span className='text-neutral-silver-100'>{formatHours(usedSpace)}</span>
                <span className='text-neutral-silver-300'>{t('global.of')}</span>
                <span className='text-neutral-silver-100'>{formatHours(totalSpace, 0)}</span>
              </div>
              <ProgressBar
                progress={percentageUsed}
                color={setColor(active, percentageUsed)}
                size='responsive'
                direction={position}
                background='gray' 
                />
          </div>
          <div className={classNames({
            'font-thunder text-4xl flex  justify-center transition duration-300 ': true,
            'text-error-red': percentageUsed >= 95,
            'text-brand-uva-light': percentageUsed < 95,
            'order-first': reverse 
          })}>
            <span className='font-thunder'>
              {percentageUsed}%
            </span>
          </div>
          {upgrade && (
            <Link className='bg-neutral-silver-700 p-2 rounded-[10px]' to={'/profile/account'}>
              <CloudIcon className={classNames({
                'h-7 w-7 transition duration-300': true,
                'text-error-red': !active && percentageUsed >= 95,
                'text-brand-uva': !active && percentageUsed < 95,
                'text-brand-gold': active,
              })} />
            </Link>
          )}
        </div>
        }
        {
          hasSubscription && (
            <div className={
              classNames({
                'flex flex-row gap-2 items-center': true,
                'flex-row-reverse md:flex-row': reverseMobile
             })
            }> 
              <p className={classNames({'!text-sm text-right text-neutral-silver-100': true, 'hidden md:block': sm})}>
                Almacenamiento <br className={classNames({
                  'hidden md:block': true,
                  'hidden': reverse
                })}/> infinito
              </p>
              <div>
                 <img src={infinity_logo} className='w-12 h-12' />
              </div>
              <Link className='bg-neutral-silver-700 p-2 rounded-[10px]' to={'/profile/account'}>
                  <CloudIconSolid className={classNames({
                  'h-7 w-7 transition duration-300 text-brand-gold': true,                  
                })}
                />
              </Link>

            </div>
          )
        }



      </div>
    </>
  )
}